<template>
  <div
    class="drivers page d-flex flex-column fill-height"
    :class="{ mobile }"
    v-resize="onResize"
  >
    <v-toolbar 
      fixed
      flat
      color="transparent"
      class="toolbar align-start flex-grow-0"
    >
      <v-text-field
        v-model="input.search.query"
        :loading="input.search.loading"
        :disabled="input.search.disabled"
        :error-messages="input.search.messages"
        :prepend-inner-icon="icons.mdiMagnify"
        solo
        flat
        dense
        :placeholder="$t('general.search')"
        clearable
        hide-details
        :color="input.search.focus ? 'primary' : undefined"
        @blur="input.search.query==null ? input.search.focus=false : input.search.focus=true"
        @focus="input.search.focus=true"
        @keyup.esc="input.search.query=null;"
        @keyup.enter="searchDriver(input.search.query, input.search);"
        :style="{ 'width': breakpoint(null,'xs') ? input.search.focus ? '50vw' : '48px' : input.search.focus ? '25vw' : '140px' }"
        style="transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1) width;"
        class="flex-shrink-0"
      />
      <v-divider
        vertical
        class="mx-2"
      />
      <driver-filter
        :filters="input"
        :tags="tags"
        :cities="cities"
        :neighborhoods="neighborhoods"
        :regions="regions"
        @change="setFilter"
      />
      <v-select
        v-model="input.limit.value"
        :items="input.limit.items"
        :loading="input.limit.loading"
        :disabled="input.limit.disabled"
        :label="$t(input.limit.label)"
        menu-props="auto, light"
        hide-details
        outlined
        dense
        color="primary"
        class="status-filter filter ma-2 ml-auto"
        style="max-width: 96px;"
      />
      <v-btn
        color="grey"
        icon
        @click="loadList(null, true);"
      >
        <v-icon>{{ icons.mdiRefresh }}</v-icon>
      </v-btn>
    </v-toolbar>
    <v-sheet
      max-width="100vw"
      color="transparent"
      class="driver-funnel d-flex flex-grow-1 mt-4 pl-16"
    >
      <v-card 
        tile
        elevation="0"
        class="step-selection fill-height mr-2 mb-2"
      >
        <v-navigation-drawer
          permanent
          floating
          touchless
          :mini-variant.sync="input.lists.toggle"
          stateless
          :expand-on-hover="breakpoint('xs')"
          :width="breakpoint(null,'xs') ? 'calc(100vw - 64px)' : '280px'"
          color="transparent"
        >
          <v-subheader
            @click="input.lists.toggle=!input.lists.toggle"
          >
            <v-icon class="mr-4">
              {{ icons.mdiViewColumnOutline }}
            </v-icon>
            {{ $t('drivers.status.title') }}
            <v-spacer />
            <v-btn
              icon
              @click="resetList"
            >
              <v-icon small>
                {{ icons.mdiCheckboxBlankOff }}
              </v-icon>
            </v-btn>
          </v-subheader>
          <!-- <v-divider /> -->
          <v-list class="transparent">
            <v-list-item 
              v-for="(l,s) in list"
              :key="'toggle-'+s"
              class="flex-shrink-0"
              @click="toggleList(s, !l.toggle)"
            >
              <v-list-item-action class="mr-4">
                <v-badge
                  :value="filterList(l.items).length>0"
                  dot
                  overlap
                >
                  <v-checkbox 
                    :input-value="l.toggle"
                    :loading="l.loading"
                    readonly
                    color="grey"
                  />
                </v-badge>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('drivers.status.'+s) }}
                  <v-badge
                    :value="filterList(l.items).length>0"
                    :content="filterList(l.items).length"
                    class="ml-2"
                  />
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-card>
      <div class="driver-funnel pl-2 pb-2 d-flex snap-x">
        <!-- TODO: permitir movimentação em massa (selecionar status) -->
        <driver-list
          v-for="(l,s) in list"
          :key="'drivers-'+s"
          :status="s"
          :title="$t('drivers.status.'+s)"
          :toggle="l.toggle"
          :list="filterList(l.items)"
          :order="l.order"
          :loading="l.loading"
          :driver="selected"
          :max-height="listHeight"
          :loading-driver="driverView.loading"
          class="snap-child flex-shrink-0 mr-2"
          @toggle-messenger="toggleMessenger"
          @toggle-batch="toggleBatch"
          @get-profile="selectDriver"
          @update="loadList"
        />
        <div style="padding-right: 8px; height: 100%;" />
      </div>
    </v-sheet>
    <driver 
      :toggle="toggleDriver"
      :data="driverProfile"
      :loading-view="driverView.loading"
      ref="driver" 
      @updated="driverUpdated"
    />
    <messenger 
      :toggle="messenger.toggle"
      :to="messenger.to"
      :user="user.username"
      :token="user.auth.token"
      @toggle-messenger="toggleMessenger"
      ref="messenger" 
    />
    <driver-batch 
      :toggle="batch.toggle"
      :status="batch.status"
      :drivers="batch.drivers"
      :user="user.username"
      :token="user.auth.token"
      @toggle-batch="toggleBatch"
      ref="driver-batch" 
    />
  </div>
</template>

<style type="text/css">

  .drivers.page {
    position: relative;
    background: var(--city-blue-50);
  }
  .drivers .toolbar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
  }
  .drivers .toolbar .filter {
    width: 160px;
  }

  .toolbar .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
    background: transparent;
  }

  .drivers .filter .v-list-item__action {
    margin-right: 16px !important;
  }

  .driver-funnel {
    position: relative;
  }

  .step-selection {
    position: absolute !important;
    left: 8px;
    z-index: 2;
    background: var(--city-blue-50) !important;
    border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
  }

  .snap-x {
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-padding: 8px;
    scroll-margin: 8px;
  }
  .snap-child {
    scroll-snap-align: start;
  }

</style>

<script>
  // Icons
  import { mdiCog, mdiMagnify, mdiCircleMedium, mdiViewColumnOutline, mdiRefresh, mdiFilterVariant, mdiStarOutline, mdiStar, mdiCheckboxBlankOff } from '@mdi/js';

  // Utilities
  import services from '@/services'
  import { sync } from 'vuex-pathify'
  import { mask } from 'vue-the-mask'
  import _ from 'lodash';
  import device from 'mobile-device-detect';
  const md5 = require('md5');

  export default {
    name: 'Drivers',
    metaInfo: {
      title: 'Motoristas'
    },

    components: {
      Driver: () => import('@/views/drivers/Driver'),
      DriverList: () => import('@/components/DriverList'),
      DriverFilter: () => import('@/components/DriverFilter'),
      DriverBatch: () => import('@/components/DriverBatch'),
      Messenger: () => import('@/components/Messenger'),
    },

    data: () => ({
      icons: {
        mdiCog,
        mdiMagnify,
        mdiCircleMedium,
        mdiViewColumnOutline, 
        mdiRefresh,
        mdiFilterVariant,
        mdiStarOutline,
        mdiStar,
        mdiCheckboxBlankOff
      },
      item: null,
      items: {},
      driverView: {
        loading: false,
      },
      messenger: {
        toggle: false,
        to: [],
      },
      batch: {
        toggle: false,
        status: null,
        drivers: [],
      },
      list: {
        'DEN': {
          // title: 'Pré-Cadastrados',
          items: [],
          order: null,
          toggle: false,
          loading: false,
          updated: false,
        },
        'REG': {
          // title: 'Pré-Cadastrados',
          items: [],
          order: null,
          toggle: false,
          loading: false,
          updated: false,
        },
        'ACC': {
          // title: 'Cadastrados',
          items: [],
          order: null,
          toggle: false,
          loading: false,
          updated: false,
        },
        'FDB': {
          // title: 'Avaliados',
          items: [],
          order: null,
          toggle: false,
          loading: false,
          updated: false,
        },
        'SIG': {
          // title: 'Aguardando Assinatura',
          items: [],
          order: null,
          toggle: false,
          loading: false,
          updated: false,
        },
        'ENR': {
          // title: 'Aguardando Docs',
          items: [],
          order: [['events.updated', 'status.changedAt', 'rating', 'job.rating', 'fullname'], ['desc', 'desc', 'desc', 'asc']],
          toggle: false,
          loading: false,
          updated: false,
        },
        'FDD': {
          // title: 'Docs Enviados',
          keys: ['FDD', 'FD+'],
          items: [],
          order: [['events.updated', 'status.changedAt', 'rating', 'job.rating', 'fullname'], ['desc', 'desc', 'desc', 'asc']],
          toggle: false,
          loading: false,
          updated: false,
        },
        'REA': {
          // title: 'Preparados',
          items: [],
          order: null,
          toggle: false,
          loading: false,
          updated: false,
        },
        'SCH': {
          // title: 'Agendamento',
          items: [],
          order: null,
          toggle: false,
          loading: false,
          updated: false,
        },
        'SC+': {
          // title: 'Agendados',
          items: [],
          order: [['calendar.start', 'rating', 'job.rating', 'fullname'], ['asc', 'desc', 'desc', 'asc']],
          toggle: false,
          loading: false,
          updated: false,
        },
        'ACT': {
          // title: 'Parceiros',
          items: [],
          order: [['rating', 'fullname'], ['desc', 'asc']],
          toggle: false,
          loading: false,
          updated: false,
        },
      },
      input: {
        lists: {
          toggle: false,
        },
        search: {
          toggle: false,
          focus: false,
          query: null,
          loading: false,
          disabled: false,
          messages: [],
          timer: null,
          cancel: null,
          results: {}
        },
        rating: {
          value: null,
          field: 'rating',
          loading: false,
          disabled: false,
          label: 'drivers.control.rating',
          items: [
            {
              text: 5,
              value: 5,
              color: 'primary',
            },
            {
              text: 4,
              value: 4,
              color: 'secondary',
            },
            {
              text: 3,
              value: 3,
              color: 'lime',
            },
            {
              text: 2,
              value: 2,
              color: 'amber',
            },
            {
              text: 1,
              value: 1,
              color: 'red',
            },
          ]
        },
        tags: {
          value: null,
          field: 'tags',
          loading: false,
          disabled: false,
          label: 'drivers.control.tags',
        },
        city: {
          value: null,
          field: 'address.city',
          loading: false,
          disabled: false,
          label: 'drivers.personal.address.city',
        },
        neighborhood: {
          value: null,
          field: 'address.neighborhood',
          loading: false,
          disabled: false,
          label: 'drivers.personal.address.neighborhood',
        },
        special_projects: {
          value: null,
          field: 'job.special_projects',
          loading: false,
          disabled: false,
          label: 'drivers.job.special_projects.label',
          items: [
            {
              text: 'drivers.job.special_projects.yes',
              value: 1
            },
            {
              text: 'drivers.job.special_projects.no',
              value: 0
            },
          ]
        },
        owner: {
          value: null,
          field: 'vehicle.isOwner',
          loading: false,
          disabled: false,
          label: 'drivers.vehicle.owner.label',
          items: [
            {
              text: 'drivers.vehicle.owner.owner',
              value: 1
            },
            {
              text: 'drivers.vehicle.owner.borrowed',
              value: 0
            },
            {
              text: 'drivers.vehicle.owner.rental',
              value: 2
            },
          ]
        },
        year: {
          value: null,
          field: 'vehicle.year',
          loading: false,
          disabled: false,
          label: 'drivers.vehicle.year',
        },
        gender: {
          value: null,
          field: 'gender',
          loading: false,
          disabled: false,
          label: 'drivers.personal.gender.label',
          items: [
            {
              text: 'drivers.personal.gender.masculine',
              value: 'M'
            },
            {
              text: 'drivers.personal.gender.feminine',
              value: 'F'
            },
            {
              text: 'drivers.personal.gender.other',
              value: 'o'
            },
            {
              text: 'drivers.personal.gender.undisclosed',
              value: '-'
            },
          ]
        },
        model: {
          value: null,
          field: 'vehicle.model',
          loading: false,
          disabled: false,
          label: 'general.model',
          items: [
            'Bolt', 
            'Celta', 
            'Classic',
            'Cobalt',
            'Corsa Classic',
            'Cruze',
            'Equinox',
            'Onix',
            'Onix +',
            'Prisma',
            'Spin',
            'Aircross',
            'C3',
            'C3 Picasso',
            'C4',
            'C4 Lounge',
            '500',
            'Argo',
            'Bravo',
            'Cronos',
            'Doblò',
            'Grand Siena',
            'Idea',
            'Linea',
            'Mobi',
            'Palio',
            'Palio Weekend',
            'Punto',
            'Siena',
            'Toro',
            'Uno',
            'Fusion',
            'Edge',
            'EcoSport',
            'Fiesta',
            'Focus',
            'Ka',
            'Ka +',
            'Accord',
            'City',
            'Civic',
            'CR-V',
            'Fit',
            'HRV',
            'WRV',
            'Azera',
            'Creta',
            'Elantra',
            'HB20',
            'HB20S',
            'HB20X',
            'ix20',
            'ix35',
            'Santa Fé',
            'Sonata',
            'Tucson',
            'Veloster',
            'Veracruz',
            'J3',
            'J5',
            'Bongo',
            'Cadenza',
            'Carens',
            'Carnival',
            'Cerato',
            'Optima',
            'Picanto',
            'Quoris',
            'Rio',
            'Sorento',
            'Soul',
            'Sportage',
            'Stinger',
            'Pajero',
            'Lancer',
            'Grand Livina',
            'Kicks',
            'Leaf',
            'Livina',
            'March',
            'Sentra',
            'Tiida',
            'Versa',
            '2008',
            '207',
            '207 Passion',
            '208',
            '3008',
            '408',
            '5008',
            'Captur',
            'Clio',
            'Duster',
            'Fluence',
            'Kwid',
            'Logan',
            'Sandero',
            'Corolla',
            'Etios',
            'Prius',
            'Yaris',
            'Space Fox',
            'Fox',
            'Gol',
            'Golf',
            'Jetta',
            'Polo',
            'T-Cross',
            'Tiguan',
            'Up',
            'Virtus',
            'Voyage',
            'Outro'
          ]
        },
        fulltime: {
          value: null,
          field: 'job.fulltime',
          loading: false,
          disabled: false,
          label: 'drivers.job.fulltime.label_abbv',
          items: [
            {
              text: 'general.yes',
              value: 1,
            },
            {
              text: 'general.no',
              value: 0,
            }
          ],
        },
        service_model: {
          value: null,
          field: 'job.service_model',
          loading: false,
          disabled: false,
          label: 'drivers.job.service_model.label',
          items: [
            {
              text: 'drivers.job.service_model.X',
              value: 'X'
            },
            {
              text: 'drivers.job.service_model.B',
              value: 'B'
            },
            {
              text: 'drivers.job.service_model.Xb',
              value: 'Xb'
            },
            {
              text: 'drivers.job.service_model.Bx',
              value: 'Bx'
            },
            {
              text: 'drivers.job.service_model.Tx',
              value: 'Tx'
            },
          ]
        },
        journey: {
          value: null,
          field: 'job.journey',
          loading: false,
          disabled: false,
          label: 'drivers.job.journey.label_abbv',
          items: [
            {
              text: 'drivers.job.journey.8-',
              value: 2
            },
            {
              text: 'drivers.job.journey.8-12',
              value: 1
            },
            {
              text: 'drivers.job.journey.12+',
              value: 0
            },
          ]
        },
        region: {
          value: null,
          field: 'job.region',
          loading: false,
          disabled: false,
          label: 'drivers.job.region.label',
        },
        job_rating: {
          value: null,
          field: 'job.rating',
          loading: false,
          disabled: false,
          label: 'drivers.job.rating.label',
        },
        app_version: {
          value: null,
          field: 'app_version',
          loading: false,
          disabled: false,
          label: 'general.app_version',
          items: [],
        },
        limit: {
          label: 'general.max',
          value: 100,
          loading: false,
          disabled: false,
          items: [
            50,
            100,
            200,
            300
          ]
        },
      },
      device: {
        mobile: device.isMobileOnly,
        tablet: device.isTablet,
        landscape: false,
        width: null,
        height: null,
      },
    }),

    computed: {
      views: sync('app/views'),
      loading: sync('app/views@loading'),
      view: sync('app/views@drivers'),
      details: sync('app/views@details'),
      main: sync('app/views@main'),
      funnel: sync('drivers/funnel@toggle'),
      drivers: sync('drivers/data@items'),
      tags: sync('drivers/data@tags'),
      cities: sync('drivers/data@cities'),
      fleets: sync('drivers/data@fleets'),
      selectedList: sync('drivers/views@dashboard.selectedList'),
      bankList: sync('drivers/bankList'),
      selected: sync('drivers/data@selected'),
      prototype: sync('drivers/prototype'),
      user: sync('user/data'),
      toast: sync('app/toast'),

      mobile () {
        return this.device.mobile && !this.device.tablet;
      },

      toggleDriver () {
        return this.selected!=null;
      },

      driverProfile () {
        return this.selected!=null ? this.items[this.selected] : null;
      },

      listHeight () {
        const mobileLandscape = this.device.landscape && this.device.mobile;
        return this.device.height - (mobileLandscape ? 0 : 64 + 16) - 64 - 48 - 16;
      },

      neighborhoods () {
        const drivers = this.items;
        const list = _.uniq(_.map(drivers, 'address.neighborhood')).sort();
        return list;
      },
      regions () {
        const drivers = this.items;
        const list = _.uniq(_.map(drivers, 'job.region')).sort();
        return list;
      }
    },

    watch: {
      $route (to, from) {
        this.updateView(to, from);
      },

      items: {
        immediate: true,
        deep: true,
        handler () {
          this.updateList();
        }
      },

      'input.search.query': function (query) {
        if (query && query.length >= 3) {
          // this.updateList();
        }
      },

      drivers: {
        immediate: true,
        deep: true,
        handler (drivers) {
          this.input.app_version.items = _.without(_.uniq(_.map(drivers, 'app_version'), 'undefined')).sort().reverse();
        }
      }
    },

    methods: {
      ...services,
      md5,

      updateList () {
        const items = _.values(this.items);
        _.each(_.omit(this.list, 'search'), (list, key) => {
            list.items = _.filter(items, item => {
              const status = item.status;
              return _.has(list, 'keys') ? _.size(_.intersection([status.value, status.super], list.keys))>0 : key==status.super;
            });
            // const empty = list.items.length==0;
            // }else if (empty) {
            //   list.toggle = false;
            // }
          // }
        });
      },

      toggleList (status, b) {
        this.list[status].toggle = b;
        if (b) this.loadList(status);
        this.funnel = _.keys(_.pickBy(this.list, l => l.toggle==true));
      },

      resetList () {
        _.each(this.list, l => {
          l.toggle = false;
        });
      },

      filterList (items) {
        let query = this.input.search.query;
        query = this.isValid(query) ? query.normalize("NFD").replace(/\p{Diacritic}/gu, "") : '';
        const list = this.searchEngine(items, query, true);
        // console.log(query, list);
        return !_.isEmpty(list) ? list : this.searchEngine(items, query);
      },

      searchEngine (items, query, exact) {
        exact = _.isNil(exact) ? false : exact;
        const key = exact ? new RegExp(`^${query}$`, 'gi') : new RegExp(query.replace(/\b +\b/g,'|'), 'gi');
        return _.filter(items, (item) => {
          const filtered = this.applyFilter(key, item);
          return filtered && (query != '' ? (key.test(item.fullname.normalize("NFD").replace(/\p{Diacritic}/gu, "")) || new RegExp(this.rawData(query), 'i').test(item.cpf) || key.test(item.cpf) || key.test(item.phone) || key.test(item.email) || key.test(item.vehicle.model) || key.test(item.vehicle.brand) || key.test(item.vehicle.plate)) : true);
        });
      },

      applyFilter (key, profile) {
        const conditions = _.map(['city', 'neighborhood', 'gender', 'owner', 'special_projects', 'model', 'year', 'rating', 'fulltime', 'service_model', 'region', 'journey', 'job_rating', 'app_version', 'tags'], condition => {
          const filter = this.input[condition];
          const value = _.get(profile, filter.field);
          let input = filter.value;
          const nil = _.isNil(input) || _.isNil(value) || (_.isArray(input)&&_.isEmpty(input));
          if (!nil) {
            input = filter.field=='address.city' ? _.map(input, i => _.first(i.split('/'))) : input;
          }
          // console.log(input);
          return nil || (_.isArray(value) ? _.isArray(input) ? _.size(_.intersection(value, input))>0 : _.some(value, val => val==input) : _.isArray(input) ? input.includes(value) : filter.field=='job.rating' ? value>=parseFloat(input) : value==input);
        });
        return _.every(conditions);
      },

      // listTitle (key) {
      //   let title = '';
      //   title = this.list[key].title;
      //   return title;
      // },

      // filterByOwner (owner, list) {
      //   const result = _.filter(list, driver => {
      //     return owner==1 ? (driver.vehicle.isOwner==1&&driver.status.value=='DOC') || driver.status.value=='SIG' : driver.vehicle.isOwner==0&&driver.status.value=='DOC';
      //   });
      //   // console.log(owner, result);
      //   return result;
      // },

      searchTimer (toggle) {
        if (toggle) {
          if (this.input.search.timer!=null) clearTimeout(this.input.search.timer);
          this.input.search.timer = setTimeout(vue => {
            vue.searchDriver(this.input.search.query);
          }, 2000, this);
        }else{
          clearTimeout(this.input.search.timer);
          this.input.search.timer = null;
        }
      },

      updateView (route, previous) {
        // set content
        const driver = this.getParam(route, 'driver');

        if (driver) {
          this.getDriver(driver);
          console.log(this.$refs);
          if (_.has(this.$refs, 'driver')) this.$refs.driver.updateView();
        }else{
          const search = this.getQuery(route, 'search');
          if (!!search) {
            this.input.search.query = search;
            this.input.search.focus = true;
            this.searchDriver(search, this.input.search);
          }
          this.selected = null;
          if (!_.isNil(previous)) {
            const id = this.getParam(previous, 'driver');
            // const profile = this.drivers[id];
            // _.merge(this.items[id], profile);
            this.updateList();
          }
        }
      },

      driverUpdated (profile) {
        this.tags = _.union(this.tags, profile.tags);
        if (profile.status.value in this.list && !this.list[profile.status.value].toggle) {
          this.list[profile.status.value].toggle = true;
        }
        this.$set(this.items, this.selected, profile);
        this.updateList();
      },

      selectDriver (id) {
        this.cacheDrivers({ [id]: this.items[id] }, this.drivers);
        setTimeout(() => {
          this.$router.push({ path: `/drivers/${id}` });
        }, 250);
      },

      toggleMessenger (b, to) {
        // console.log('messenger:', b, to);
        this.messenger.toggle = b;
        this.messenger.to = to;
      },

      toggleBatch (b, drivers=[], status=null) {
        console.log('batch:', b, drivers);
        this.batch.toggle = b;
        this.batch.drivers = drivers;
        this.batch.status = status;
      },

      setFilter (key, value) {
        if (_.isNil(key)) {
          // clear filters
          _.each(this.input, field => {
            field.value = null;
          });
        }
        if (key=='city') {
          const save = _.reduce(value, (result, city) => {
            if (!this.cities.includes(city)||this.cities.includes(_.first(city.split('/'))+'/UF')) {
              result.push(city.includes('/') ? city : city+'/UF');
              if (this.cities.includes(_.first(city.split('/'))+'/UF')) result.push('-'+_.first(city.split('/'))+'/UF');
            }
            return result;
          }, []);
          _.each(save, city => {
            if (_.startsWith(city, '-')) {
              _.pull(this.cities, city.replace('-', ''));
              _.pull(save, city);
            }
          })
          this.cities = _.uniq([...this.cities, ...save]);
          // value = _.map(value, v => _.first(v.split('/')));
        }
        this.input[key].value = value;
      },

      parseFilters () {
        let filters = {}
        const city = this.input.city.value;
        if (!_.isNil(city)&&!_.isEmpty(city)) filters['cidade'] = _.map(city, v => _.first(v.split('/')));
        const neighborhood = this.input.neighborhood.value;
        if (!_.isNil(neighborhood)&&!_.isEmpty(neighborhood)) filters['bairro'] = neighborhood;
        const model = this.input.model.value;
        if (!_.isNil(model)&&!_.isEmpty(model)) filters['modelo'] = model;
        const owner = this.input.owner.value;
        if (!_.isNil(owner)&&!_.isEmpty(owner)) filters['dono'] = owner;
        const year = this.input.year.value;
        if (!_.isNil(year)&&!_.isEmpty(year)) filters['ano_fab'] = parseInt(year);
        const fulltime = this.input.fulltime.value;
        if (!_.isNil(fulltime)&&!_.isEmpty(fulltime)) filters['nivel_env'] = parseInt(fulltime);
        const service_model = this.input.service_model.value;
        if (!_.isNil(service_model)&&!_.isEmpty(service_model)) filters['mod_trab'] = parseInt(service_model);
        const job_rating = this.input.job_rating.value;
        if (!_.isNil(job_rating)&&!_.isEmpty(job_rating)) filters['nota_app'] = parseInt(job_rating);
        const journey = this.input.journey.value;
        if (!_.isNil(journey)&&!_.isEmpty(journey)) filters['nr_rodadas'] = parseInt(journey);
        const special_projects = this.input.special_projects.value;
        if (!_.isNil(special_projects)&&!_.isEmpty(special_projects)) filters['special_projects'] = special_projects;
        const gender = this.input.gender.value;
        if (!_.isNil(gender)&&!_.isEmpty(gender)) filters['genero'] = gender;
        const rating = this.input.rating.value;
        if (!_.isNil(rating)&&!_.isEmpty(rating)) filters['destaque'] = _.map(rating, (r) => r-3);
        const tags = this.input.tags.value;
        if (!_.isNil(tags)&&!_.isEmpty(tags)) filters['tags'] = tags;
        return filters;
      },

      loadList (status, update) {
        if (!_.isNil(update)&&update) _.each(this.list, l => {
          l.updated = false;
        });
        if (_.isNil(status)) {
          status = _.findKey(this.list, { 'updated': false, 'toggle': true });
          // status = _.findKey(this.list, { 'updated': false });
          if (_.isNil(status)) return false;
        }
        // console.log('get list:', status);
        const params = _.assign({}, { 
          'status': status, 
          'qtd': this.input.limit.value, 
          'lite': 1, 
          'calendar': status=='SC+'? 1 : 0 
        }, this.parseFilters());
        this.getDriverList(params, this.list[status], this.loadList);
      },

      getDriverList (params, loader, callback) {
        if (params.status=='ACT') _.unset(params, 'qtd');
        console.log('get list for ', params);
        this.input.search.messages = [];
        loader.loading = true;
        
        const username = this.user.username;
        const token = this.user.auth.token;

        const query = _.reduce(params, (s,v,k) => {
          return s + (_.isNil(v) ? '' : '&'+k+'='+v);
        },'');
        
        this.$api.COM({
          url: '/searchprofiledriverextadmin/'+username+'/'+token,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          data: params,
        })
        .then(response => {
          console.log('getDriverList => ',response);
          if(response.data.retorno==200){
            
            if (response.data.dados.length>0) {
              let drivers = this.processDrivers(response.data.dados, this.prototype);
              if (params.status=='ACT') {
                drivers = this.cacheDrivers(drivers, this.drivers, 'ACT');
              }
              this.items = Object.assign({}, this.items, drivers);
            }

          }else if(response.data.retorno==404){
            // no results
          }else if(response.data.retorno==401){
            this.getout();
            this.handleError(response.data.retorno, this.$t('errors.session_expired'));
          }else{
            this.handleError(response.data.retorno, this.$t('errors.unknown'));
          }
        })
        .catch(error => {
          this.handleError(error);
        })
        .finally(() => {
          if (_.has(params, 'status')) this.list[params.status].updated = true;
          loader.loading = false;
          if (!_.isNil(callback)) callback();
        });
      },
      searchDriver (query, loader, callback) {
        console.log('searching for ', query);
        this.input.search.messages = [];
        loader.loading = true;
        
        const username = this.user.username;
        const token = this.user.auth.token;

        this.$api.COM({
          url: '/searchprofiledriveradmin/'+username+'/'+token+'/'+query,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          data: {
            'lite': 1
          },
        })
        .then(response => {
          console.log('searchDriver => ',response);
          if(response.data.retorno==200){
            
            if (response.data.dados.length>0) {
              const items = this.processDrivers(response.data.dados, this.prototype);
              this.items = Object.assign({}, this.items, items);
            }else{
              this.toggleToast(
                true,
                this.$t('drivers.no_search_results')+' '+query,
                5000,
                false,
              );
            }

          }else if(response.data.retorno==404){
            this.input.search.messages = [this.$t('drivers.no_results')];
          }else if(response.data.retorno==401){
            this.getout();
            this.handleError(response.data.retorno, this.$t('errors.session_expired'));
          }else{
            this.handleError(response.data.retorno, this.$t('errors.unknown'));
          }
        })
        .catch(error => {
          this.handleError(error);
        })
        .finally(() => {
          loader.loading = false;
          if (!_.isNil(callback)) callback();
        });
      },
      
      getDriver (id) {
        console.log(true,'driver.getDriver');
        this.input.search.messages = [];
        this.driverView.loading = true;
        
        const username = this.user.username;
        const token = this.user.auth.token;
        this.selected = id;
        
        this.$api.COM
          .get('/getprofiledriveradmin/'+username+'/'+token+'/'+id+'/?format=json')
          .then(response => {
            console.log('getDriver => ',response);
            if(response.data.retorno==200){
              response.data.viewedAt = this.$moment().format();
              // push driver to items list
              const items = this.processDrivers(response.data, this.prototype);
              this.items = Object.assign({}, this.items, items);
              // this.cacheDrivers(items, this.drivers);

              this.$nextTick(() => {
                // selected driver
                if (_.has(this.$route.params, 'driver')) {
                  if (_.has(this.$refs, 'driver')) this.$refs.driver.updateView();
                }
              })

            }else if(response.data.retorno==404){
              this.input.search.messages = [this.$t('drivers.driver_not_found')];
            }else if(response.data.retorno==401){
              this.getout();
              this.handleError(response.data.retorno, this.$t('errors.session_expired'));
            }else{
              this.handleError(response.data.retorno, this.$t('errors.unknown'));
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
            this.driverView.loading = false;
            console.log(false,'driver.getDriver');
          });
      },

      getTags () {
        console.log('driver.getTags');
        const username = this.user.username;
        const token = this.user.auth.token;

        this.$api.COM
          .get('/driver/tags/'+username+'/'+token+'/?format=json')
          .then(response => {
            console.log('getTags => ', response);
            if(response.data.length>0){
              this.tags = response.data;
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
          });
      },

      getFleets () {
        console.log('driver.getFleets...');
        const username = this.user.username;
        const token = this.user.auth.token;

        const params = {
          username,
          token,
        };

        this.$api.COM
          .get('/fleet/', { params })
          .then(response => {
            console.log('getFleets => ', response);
            this.fleets = response.data;
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
          });
      },

      getBankList () {
        console.log('driver.getBanklist');
        const username = this.user.username;
        const token = this.user.auth.token;

        this.$api.COM
          .get('/getbancosadmin/'+username+'/'+token+'/?format=json')
          .then(response => {
            console.log('getBankList => ', response);
            if(response.data.length>0){
              this.bankList = response.data.map(function(item){
                return { id: item.id_banco, code: item.cod_banco, title: item.nm_banco };
              });
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
          });
      },

      onResize () {
        this.device.width = window.innerWidth;
        this.device.height = window.innerHeight;
        this.device.landscape = this.device.width > this.device.height;
        console.log('display update', this.device.mobile, this.device.landscape);
      },
    },

    created () {
      this.getBankList();
      this.getTags();
      this.getFleets();
    },

    mounted () {
      this.items = _.clone(this.drivers);
      _.each(this.list, (list, key) => {
        this.$set(this.list[key], 'toggle', _.indexOf(this.funnel, key) >= 0);
      });
      // _.each(this.funnel, key => {
      //   this.$set(this.list[key], 'toggle', true);
      // });
      this.updateView(this.$route);
      this.loadList();
      this.onResize();
    },

    directives: {
      mask,
    },

  }

</script>
